/* MapView container */
.map-container {
  position: relative;
  height: 100%;
  width: 100%;
}

/* Styling for the map container */
.map-container .mapboxgl-map {
  height: 100%;
  width: 100%;
}

/* map.css */
.search-container {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
}

.search-container input[type="text"] {
  width: 300px;
  height: 30px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.menu-button {
  position: absolute;
  color: grey;
  top: 20px;
  right: 35px;
  z-index: 1000;
}

.menu-button:hover {
  color: black;
}

.menu {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: calc(100% + 5px);
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 10px;
  z-index: 10000;
}

.menu ul {
  list-style-type: none;
  padding: 0;
}

.menu li:hover {
  background-color: #f0f0f0;
}

.menu p {
  padding: 10px;
  margin: 1px;
}

.menu p:hover {
  background-color: #f0f0f0;
}

.menu-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 8px;
  margin: 6px;
}

.menu-item:hover {
  background-color: #f0f0f0;
}

.menu-text {
  font-size: 10px; /* Adjust font size as needed */
}

.upload-modal {
  position: fixed;
  background-color: white;
}

.marker-house {
  background-image: url('/public/markers/house/icons8-home-100.png');
  background-size: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}

.marker-park {
  background-image: url('/public/markers/park/icons8-park-100.png');
  background-size: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}

.marker-building {
  background-image: url('/public/markers/building/icons8-building-100.png');
  background-size: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}

.marker-resto {
  background-image: url('/public/markers/resto/icons8-restaurant-100.png');
  background-size: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}

.marker-obj {
  background-image: url('/public/markers/object/icons8-3d-object-96.png');
  background-size: cover;
  width: 35px;
  height: 35px;
  cursor: pointer;
}

.mapboxgl-popup-tip {
  background-color: transparent;
  border-top-color: transparent;
}

.mapboxgl-popup {
  min-width: 286px;
}

.mapboxgl-popup-content {
  padding: 0;
  text-align: center;
  padding: 0;
  overflow: hidden;
  object-fit: cover;
  border-radius: 10px;
}

.marker-info-window {
  position: relative;
  max-height: 207px;
  display: block;
  flex-direction: column;
  align-items: center;
  padding: 0px;
}

.marker-info-window a { 
  text-decoration: none;
} 

.marker-info-window p {
  font-size: 14px;
}

.marker-thumbnail {
  overflow: auto;
}

.thumbnail-img {
  width: 100%;
}

.thumbnail-description {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 10px;
  text-align: center;
}
