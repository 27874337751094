.faq-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  margin:100px;
  font-family: 'Arial', sans-serif;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.faq-title {
  text-align: center;
  color: #333;
  font-size: 2em;
  margin-bottom: 20px;
}

.faq-item {
  margin-bottom: 20px;
}

.faq-question {
  font-weight: bold;
  color: #555;
  margin-bottom: 10px;
  font-size: 1.1em;
}

.faq-answer {
  color: #666;
  margin-left: 20px;
  font-size: 1em;
}

.faq-link {
  color: #007bff;
  text-decoration: none;
}

.faq-link:hover {
  text-decoration: underline;
}
