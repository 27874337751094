.splat-wrapper-container {
  width: 100%;
  height: 100%;
}

#viewer-container {
  width: 100%;
  height: 100%;
}

.parent-splat-viewer {
  position: relative;
}

.splat-viewer-footer p {
  z-index: 10000;
  position: fixed;
  padding: 10px;
  margin: 0px;
  background-color: white;
  opacity: 0.3;
  font-weight: 500;
  bottom: 0;
}

#progress {
  z-index: 10000;
  position: absolute;
  width: 75px;
  height: 75px;
  background: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
  border-radius: 50%; /* Make it circular */
  display: flex;
  justify-content: center;
  align-items: center;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); /* Center the element */
}

.spinner {
  border: 2px solid rgba(255, 255, 255, 0.3); /* Light gray */
  border-top: 2px solid rgba(52, 152, 219, 1); /* Blue */
  border-radius: 100%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.comp-icon {
  position: absolute;
  top: 10px; /* Adjust this value to fine-tune the distance from the top */
  right: 10px; /* Adjust this value to fine-tune the distance from the right */
  z-index: 100000; /* Ensure it is on top of other elements */
  width: 40px; /* Set the desired width */
  height: 40px; /* Set the desired height */
}

.comp-icon img {
  width: 100%; /* Make the image take up the full width of the container */
  height: 100%; /* Make the image take up the full height of the container */
  object-fit: contain; /* Ensure the image fits within the container without being distorted */
}