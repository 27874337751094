.catalog-header {
  color: white;
  display: flex;
  padding: 20px;
  margin-bottom: 10px;
}

.catalog-header img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.catalog-grid-container {
  margin: 0 auto;
  width: 1000px;
  padding-left: 0;
  gap: 20px;
}

.grid-item {
  display: inline-block;
  list-style-type: none;
  text-align: center;
  width: 200px; /* Set a fixed width for the grid item */
  height: 200px; /* Set a fixed height for the grid item */
  overflow: hidden; /* Ensure content inside the grid item is clipped if it overflows */
  position: relative; /* Position relative for absolute positioning of the image */
  border-radius: 20px;
  margin:20px;
}

.grid-item img{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.thumbnail-text {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 10px;
  text-align: center;
}

.catalog {
  padding: 10px;
}

h1 {
  font-size: 24px;
  font-weight: 500;
  margin: 0;
  text-align: center;
  padding: 10px;
  color: white;
}

@media screen and (max-width: 430px) { .catalog-grid-container { width: 200px; } }
@media screen and (min-width: 431px) and (max-width: 630px) { .catalog-grid-container { width: 400px; } }
@media screen and (min-width: 631px) and (max-width: 830px) { .catalog-grid-container { width:600px;  } }
@media screen and (min-width: 831px) and (max-width: 1030px) { .catalog-grid-container { width: 800px; } }
@media screen and (min-width: 1031px) and (max-width: 1230px) { .catalog-grid-container { width: 1000px; } }