body {
  font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: black;
  color: white;
  background-image: url('/public/landing.jpg');
  background-repeat: no-repeat; /* Ensure the background image doesn't repeat */
  background-size: contain; /* Adjust the size of the background image */
  background-position: center; /* Align the background image in the center */
}

.content h1 {
  font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 128px;
  font-weight: 350;
}

.content p {
  font-family: 'SF Pro Text', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  /* Add additional fallback fonts if needed */
}

.contact-section {
  padding: 50px 0;
  text-align: center;
}

.icon {
  margin-bottom: 20px;
}

.icon img {
  width: 100px; /* Adjust size as needed */
  height: 100px;
}

.content {
  text-align: center;
}

@media (max-width: 768px) {
  .content h1 {
    font-size: 45px; /* Adjust the font size for smaller screens */
  }

  .content p {
    font-size: 16px; /* Adjust the font size for smaller screens */
  }
}
