html,
body,
#root {
  height: 100vh;
  padding: 0;
  margin: 0;
  background: #000000;
  font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-weight: 150;
}

#wrapper-container {
  display:contents;
}

@media (max-width: 768px) {
  #viewer-container {
    position:fixed;
    width: 100%;
    height: 100%;
  }
  
  #wrapper-container {
    display:contents;
  }
}
